import * as React from 'react'
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from '../components/Container'
import { PageTitle } from '../components/Titles'
import { BackButton, DownloadButton } from '../components/Button'

const ChartTemplate = ( {data} ) => {

    const artistName = "Milo Walker Johnson";
    const metaTitle = `${data.wpChart.title} by ${artistName}`;

    const imageData = getImage(data.wpChart.featuredImage?.node?.localFile)

    // const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    if (!imageData) return null

   

    return (
        <Layout>
            <Seo title={metaTitle} />
            <Container size={`lg`}>
                <div className={`py-2 -mb-5 md:-mx-[0.5rem] lg:z-10 lg:absolute lg:left-auto lg:top-0 lg:right-14`}>
                    <BackButton variation={`outline`} onClick={() => navigate(-1)}>
                        Back
                    </BackButton>
                </div>
            </Container>
            <Container size={`md`} className={`py-16 md:py-20 text-center relative`}>
            <div className={`mb-10 space-y-5`}>
            <PageTitle size={`text-lg text-accent`}>
                {data.wpChart.title}
            </PageTitle>
            <p className={'mb-0 pt-2'}>By <strong>{artistName}</strong></p>
            {data.wpChart.chartSettings.musicObjectSelect &&
            <p className={`mb-0 before:content-['“'] after:content-['”']`}>{data.wpChart.chartSettings.musicObjectSelect.title}</p>
            }
            </div>
            
            <GatsbyImage 
                    image={imageData}
                    layout={`fullWidth`}
                    className={`w-full h-full max-w-4xl item-shadow pointer-events-none`}
                    alt={data.wpChart.featuredImage.node.altText || data.wpChart.title}
                    />


            <div className="mt-10 mb-10 md:mt-14 flex md:flex-row flex-col justify-center items-center max-w-4xl mx-auto">
              <p className={`m-0 md:pr-7 md:pb-0 pb-7 md:text-xl`}>
                <strong>{data.wpChart.title}</strong> sheet music is available here:
              </p>
              <DownloadButton fileUrl={data.wpChart.featuredImage.node.localFile.publicURL} filename={data.wpChart.featuredImage.node.localFile.name}>
                Download
              </DownloadButton>
              </div>
            </Container>
        </Layout>
    )

}

export default ChartTemplate 

export const query = graphql`
  query($id: String) {
    wpChart(id: { eq: $id }) {
      title
      id 
      slug
      date
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            name
            childImageSharp {
              gatsbyImageData(
                  quality: 85
              )
            }
            
          }
          
        }
      }
      chartSettings {
        musicObjectSelect {
          ... on WpMusicRelease {
            id
            slug
            title
          }
        }
      }
    }
  }
`